<template>
  <div class="append-label">
    {{ text }}
  </div>
</template>

<script setup lang="ts">
import type { IAppendLabelProps } from './AppendLabel.types';

const props = defineProps<IAppendLabelProps>();

const styleVariables = computed(() => ({
  labelBackground: GlobalUtils.CSS.getBackgroundColor(props.color),
  textColor: props.textColor,
}));
</script>

<style scoped lang="scss">
.append-label {
  --label-background: v-bind('styleVariables.labelBackground');
  --text-color: v-bind('styleVariables.textColor');
}
</style>

<style scoped lang="scss" src="./AppendLabel.scss" />
