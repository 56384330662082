<template>
  <div v-if="changeXL(true, false)" class="free-rewards__container">
    <NuxtLinkLocale class="free-rewards" target="_blank" :to="redirectTo" @click="MainPageEvents.smallEventClicked()">
      <Component :is="icon" class="free-rewards__icon" />
    </NuxtLinkLocale>
  </div>
</template>
<script setup lang="ts">
import type { IFreeRewardsProps } from '~/features/mainPage/components/FreeRewards/FreeRewards.types';
import { MainPageEvents } from '~/repository/amplitude/events/mainPage';

defineProps<IFreeRewardsProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');
</script>
<style lang="scss" scoped src="./FreeRewards.scss"></style>
