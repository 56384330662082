<template>
  <SkeletonComponentLoader>
    <section v-if="!isLoaded" class="layout-controller-skeleton">
      <SkeletonBaseBackground class="layout-controller-skeleton__body" v-bind="bodySizes">
        <SkeletonBaseForeground :width="imageSize" :height="imageSize" :border-radius="600" />
        <div class="layout-controller-skeleton__body-text">
          <SkeletonBaseForeground :width="changeXL(240, 160)" v-bind="textsSizes" />
          <SkeletonBaseForeground :width="changeXL(400, 240)" v-bind="textsSizes" />
        </div>
      </SkeletonBaseBackground>
    </section>
    <MainPageLayoutController v-else />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';

const eventStore = useEventStore();
const { isLoaded: eventLoaded } = storeToRefs(eventStore);

const userStore = useUserStore();
const { isLoaded: userLoaded } = storeToRefs(userStore);

const { changeByMedia } = GlobalUtils.Media;

const changeXL = changeByMedia('xl');
const changeMD = changeByMedia('md');

const isLoaded = computed(() => eventLoaded.value && userLoaded.value);

const bodySizes = computed(() => ({
  borderRadius: changeXL(32, changeMD(24, 16)),
  height: changeXL(524, changeMD(358, 338)),
}));

const imageSize = computed(() => changeXL(224, 144));

const textsSizes = computed(() => ({
  borderRadius: changeXL(8, 6),
  height: changeXL(40, 28),
}));
</script>

<style scoped lang="scss" src="./LayoutController.skeleton.scss" />
